import { NavSection } from 'design-system/inPageNavigation/InPageNavigation';

export const sections: NavSection[] = [
    { id: 'administrative', label: 'Administrative' },
    { id: 'nameInformation', label: 'Name information' },
    { id: 'otherInformation', label: 'Other information' },
    { id: 'addresses', label: 'Address' },
    { id: 'phoneEmail', label: 'Phone & email' },
    { id: 'raceEthnicity', label: 'Ethnicity and race' },
    { id: 'identifications', label: 'Identification' }
];
